

















































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import {
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BPagination,
  BButton,
  BCol,
  BRow,
  BCard,
  BInputGroup,
  BCardTitle,
  BFormTags,
  BForm,
  BFormSelectOption,
} from "bootstrap-vue";
import { VForm } from "@/global";

import { VueAutosuggest } from "vue-autosuggest";

@Component({
  components: {
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BFormTags,
    BForm,
    VueAutosuggest,
  },
})
export default class AutoOnboarding extends Vue {
  $refs!: {
    form: VForm;
  };

  item: RegistrationsAPI.AutoOnboardings.AutoOnboarding = null;

  @Watch("id", { immediate: true })
  async watchId() {
    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  editing = false;

  get isEditing() {
    return this.id == "create" || this.editing;
  }

  async getItem() {
    if (this.id == "create") {
      this.$set(this, "item", {
        name: "",
        flowId: "",
        employeeId: "",
        divisionId: "",
        vacancyReferenceIds: [""],
        statusTriggers: [],
      });

      return;
    }

    const item = await this.$api.registrations.autoonboarding.Get(this.id);

    if (item.employeeId != null) {
      const employee = await this.$api.employee.user.Get(item.employeeId);
      this.suggestions.push(employee);
      this.$set(this, "selected", employee);
    }
    this.$set(this, "item", item);
  }

  async saveChanges() {
    if (!(await this.$refs.form.validate())) return;

    if (this.editing) return await this.update();

    if (this.id == "create") return await this.create();
  }

  async update() {
    const res = await this.$api.registrations.autoonboarding.Update(
      this.item.id,
      { ...this.item }
    );
    this.editing = false;
    this.$set(this, "item", res);
  }

  async create() {
    const res = await this.$api.registrations.autoonboarding.Create({
      ...this.item,
    });
    if (res != null) {
      await this.$router.push({
        name: this.$route.name,
        params: {
          identifier: `${res.id}`,
        },
      });
    }
  }

  newStatusTriggerId: number | null = null;

  addStatusTrigger() {
    if (this.newStatusTriggerId == null) return;
    this.item.statusTriggers.push({
      status: this.newStatusTriggerId,
      flowId: null,
    });

    this.newStatusTriggerId = null;
  }

  statusName(status: number) {
    return this.statuses.find((x) => x.id == status).value;
  }

  get statuses() {
    return [
      { id: 1, value: "Invitation sent" },
      { id: 2, value: "Link clicked" },
      { id: 3, value: "First answers received" },
      { id: 4, value: "Answers submitted" },
      { id: 5, value: "Sent back to candidate" },
      { id: 6, value: "Registration completed" },
      { id: 7, value: "Registration approved" },
      { id: 8, value: "Sent to CRM" },
      { id: 9, value: "Cancelled by candidate" },
      { id: 10, value: "Started" },
      { id: 11, value: "Completed" },
    ];
  }

  get unusedStatuses() {
    const usedIds = this.item.statusTriggers.map((x) => x.status);
    console.log(usedIds);
    return this.statuses.filter((x) => !usedIds.includes(x.id));
  }

  employeeQuery = null;
  suggestions = [];
  debounceMilliseconds = 250;
  timeout = null;
  inputProps = {
    id: "autosuggest__input_ajax",
    placeholder: "Search for an employee to attach registrations to.",
    class: "form-control",
    name: "ajax",
  };
  selected = null;

  sectionConfigs = {
    employees: {
      limit: 6,
      label: "Employees",
      onSelected: (item, originalInput) => {
        this.selectEmployee(item.item);
      },
    },
  };

  selectEmployee(suggestedItem) {
    this.$set(this, "selected", suggestedItem);
  }

  fetchResults() {
    const { employeeQuery } = this;

    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const list = this.$api.employee.user.GetList(1, 10, employeeQuery);

      Promise.all([list]).then((values) => {
        this.suggestions = [];

        this.suggestions.push({ name: "employees", data: values[0].items });
      });
    }, this.debounceMilliseconds);
  }

  @Watch("selected", { immediate: true, deep: true })
  watchSelectedEmployee() {
    if (this.selected?.uuid == null) return;
    this.$set(this.item, "employeeId", this.selected.uuid);
  }

  get selectedName() {
    return this.selected == null
      ? ""
      : `${this.selected?.fullName} (${this.selected?.email})`;
  }
}
